import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import styled from '@emotion/styled'
import Layout from '../components/layout'
import SEO from '../components/seo'
import {
  StyledSection,
  SectionBody,
  FullPageStyles,
  PageTitleWrapperStyles,
} from '../components/pageStyles'

const Wrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  grid-column-gap: 6px;
  grid-row-gap: 6px;
  width: 100%;
  text-align: center;
`

const Item = styled.div`
  flex: 1 1 100%;
  padding: 20px 20px 20px 20px;

  h1 {
    flex: 1;
    font-family: Noto Sans, serif;
    font-size: 2rem;
    font-weight: 700;

    border-bottom: 2px dotted rgb(242, 176, 200);
    text-align: center;
    margin-bottom: 1rem;
  }

  h3 {
    flex: 1;
    font-family: Noto Sans, serif;
    font-size: 1.5rem;
    font-weight: 700;

    border-bottom: 2px dotted rgb(242, 176, 200);
    text-align: center;
    margin-bottom: 1rem;
  }

  em {
    font-size: 2rem;
    color: rgb(242, 176, 200);
    font-family: Gloria Hallelujah, cursive;
  }

  ul {
    text-align: left;
  }

  p {
    text-align: justify;
  }
`
const Services = () => {
  const {
    servicesJson: { title },
  } = useStaticQuery(graphql`
    query servicesQuery {
      servicesJson {
        title
        description
      }
    }
  `)
  return (
    <Layout>
      <SEO pageTitle={title} />
      <PageTitleWrapperStyles>
        <SectionBody>
          <Item>
            <h3 className="heading">
              All Grooming Sessions are All Inclusive & All Appointments are
              customized for your pets needs.
            </h3>
          </Item>
        </SectionBody>
      </PageTitleWrapperStyles>
      <FullPageStyles>
        <StyledSection>
          <Wrapper>
            <Item>
              <PageTitleWrapperStyles>
                <h3>
                  <em>Included Services</em>
                </h3>
                <ul>
                  <li>
                    <strong>Bathed Twice&nbsp;</strong>
                    with appropriate shampoo for the individual skin and coat
                    type
                  </li>
                  <li>
                    Tearless <strong>Blueberry Facial</strong>
                  </li>
                  <li>
                    <strong>Ears are cleaned&nbsp;</strong>
                    (Plucked if requested)
                  </li>
                  <li>
                    <strong>Pad hair&nbsp;</strong>
                    is trimmed
                  </li>
                  <li>
                    <strong>Nails</strong> Are Cut <u>AND&nbsp;</u>
                    <strong>Filed</strong>
                    <span>
                      (If your pet allows it, this will make your pets nice and
                      smooth after they&apos;re cut)
                    </span>
                  </li>
                  <li>
                    <strong>Sani-Trim&nbsp;</strong>- We trim
                    <span>
                      around your pet’s private area to make sure he or she
                      stays clean and fresh If necessary
                    </span>
                  </li>
                  <li>
                    <strong>Hand drying&nbsp;</strong>
                    (no cage drying)
                  </li>
                  <li>
                    <strong>Fluff/Comb Out&nbsp;</strong>

                    <span>
                      We hand comb through every inch of your pet to ensure that
                      there are no mats or leftover loose fur.
                    </span>
                  </li>
                  <li>
                    <strong>De-shed&nbsp;</strong>
                    and removal of the excess undercoat
                    <span>if necessary</span>
                  </li>
                  <li>
                    <strong>Haircut suited to your pet&nbsp;</strong>I
                    Specialize in Cute & Creative Styles. Breed Standard Styles
                    can also be done. Upon arrival, your dog will be given a
                    full consultation & We can determine what kind of haircut
                    will best suit them.
                    {/* eslint-disable-next-line no-irregular-whitespace */}
                    <span>​</span>
                  </li>
                  <li>
                    <span>
                      <strong>Teeth Brushed & Breath Freshened</strong>
                    </span>
                  </li>
                  <li>
                    <strong>Light trim of </strong>
                    <u>face & feet </u>If Needed
                  </li>
                  <li>
                    <strong>Silky Finishing Spray</strong>
                  </li>
                  <li>
                    <strong>Bow &/or Bandana</strong>
                  </li>
                </ul>
              </PageTitleWrapperStyles>
            </Item>
            <Item>
              <h3>
                <em>Puppy Intro</em>
              </h3>
              <strong>Everything listed in the Included Services</strong>
              <p>
                You wouldn&apos;t rush &amp; start buzzing a babies hair right?
                So why do that to your puppy? Puppies need to get used to the
                grooming process.&nbsp;
                <br />
                In your puppy&apos;s first grooming session, we&apos;ll get them
                used to the bath, combs, brushes, and scissors.
              </p>

              <em>
                Every puppy gets a Spa Bath, Blow Dry and Face Trim.&nbsp;
              </em>
            </Item>
            <Item>
              <PageTitleWrapperStyles>
                <h3>
                  <em>Upgrades, Add-On&apos;s & Extras</em>
                </h3>
                <u>
                  <strong>Flea Clean Up: ($125+)</strong>
                </u>

                <p>
                  <strong>I do not </strong>groom any pets with fleas, but
                  should I miss it upon your pet&apos;s assessment or see any
                  fleas in the tub while washing your dog, I will give your pet
                  a flea dip, and The van will require a full deep cleaning
                  after your pets appointment.
                </p>
              </PageTitleWrapperStyles>
            </Item>
          </Wrapper>
        </StyledSection>
      </FullPageStyles>
    </Layout>
  )
}
export default Services
